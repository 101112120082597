import Vue from "vue"
import DatePicker from 'vue2-datepicker'
import SortedTablePlugin from "vue-sorted-table"
import Payments from '../widget-payment-create'
import Paginate from "@/components/widgets/pagination.vue";

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>'
})
export default {
  components: {
    DatePicker,
    Payments,
    Paginate
  },
  data: function() {
    return {
      busy: false, 
      advanced: false,
      status: null,
      createId: null,
      openedCreate: false,
      sortDesc: false,
      totalItem: 0,
      apiStatus: 'ไม่พบข้อมูล',
      doc: {
        start: null,
        end: null
      },
      duedate: {
        start: null,
        end: null
      },
      debtStatus: [
        { text: 'On hold', value: 'onhold' },
        { text: 'ค้างชำระ', value: 'overdue' },
        { text: 'ยกเลิก', value: 'cancel' },
        { text: 'ชำระแล้ว', value: 'success' }
      ],
      perPage: 6,
      sortBy: 'name',
      currentPage: 1,
      currentData: {},
      keyword: "",
      fields: [
        { 
          key: 'bill_code', 
          label: 'เลขที่เอกสาร', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'created_at', 
          label: 'วันที่เอกสาร',
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'bill_customer_name', 
          label: 'ชื่อลูกหนี้',
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
        },
        { 
          key: 'bill_nettotal_amt', 
          label: 'ยอดค้างชำระ',
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true, 
          class: 'text-right' 
        },
        { 
          key: 'status', 
          label: 'สถานะ',
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'bill_manipulator', 
          label: 'ผู้รับผิดชอบ', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
        },
        { 
          key: 'action', 
          label: '#', 
          class: 'text-right' 
        }
      ],
      items: []
    }
  },
  computed: {
    rows() {
      return this.items.length
    }
  },
  mounted() {
    this.onInitData()
  },
  methods: {
    ondeleted(id) {
      this.$swal({
        width: "29rem",
        padding: '1.825em',
        html: '<div class="d-flex flex-row align-items-center">' +
          '<i class="fa fa-2x mr-3 fa-exclamation-triangle text-info"></i>'+
          '<div class="d-flex flex-column">'+
          '<span class="font-bold text-base py-2.5 text-left">คุณต้องการจะลบข้อมูลนี้หรือไม่?</span>'+
          '<span class="text-base text-left text-gray-500">หากคุณยืนยันแล้ว ข้อมูลจะไม่สามารถกู้คืนกลับมาได้?</span>'+
          '</div>'+
        '</div>',
          allowOutsideClick: false,
          focusConfirm: false,
          customClass: {
            popup: 'swal2-custom-rounded',
            closeButton: 'text-3xl swal2-custom-close',
            actions: 'justify-content-end',
            contant: 'd-flex flex-row justify-content-around px-2',
            title: 'd-none',
            confirmButton: "btn btn-info py-3 rounded-lg text-white font-prompt-light text-sm font-normal",
            cancelButton: 'btn btn-google py-3 rounded-lg text-white font-prompt-light text-sm font-normal'
          },
          cancelButtonColor: '#dc4a38',
          cancelButtonText: 'ยกเลิก',
          confirmButtonText: "ตกลง",
          showCancelButton: true,
          showCloseButton: true,
      }).then(async confirm => {})
    },
    onEventPayment(id) {
      this.createId = id
      this.openedCreate = !this.openedCreate
    },
    onCreateClose(e) {
      if (e.status) {
        this.openedCreate = false
        this.onInitData()
      }
    },
    async onInitData(currentPage) {
      try {
        this.busy = true
        this.apiStatus = 'กำลังค้นหาข้อมูล..'

        const page = currentPage ? currentPage : 1
        const url = `${Vue.prototype.$host}/api/v1/bills?page=${page}&perPage=8&keyword=${this.keyword}`
        const param = {
          keyword: this.keyword,
          url: url
        }
        const result = await this.$store.dispatch('Bills/getAll', param, { root: true })
        setTimeout(() => {
          this.busy = false
          this.apiStatus = 'ไม่พบข้อมูล'
          
          if (result) {
            this.currentData = result.data;
            this.items = result.data.data
            this.totalItem = result.data.total
          } else {
            this.items = []
            this.totalItem = 0
          }
        }, 500)
      } catch (error) {
        this.busy = false
        this.apiStatus = 'ไม่พบข้อมูล'
        this.onExceptionHandler(error.response.data.message);
      }
    }
  }
}